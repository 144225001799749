





















import Vue from 'vue'
import Nav from '@/components/Nav.vue'
import ProductForm from '@/components/ProductForm.vue'
import { Product } from '@/interfaces'

export default Vue.extend({
  components: { Nav, ProductForm },
  props: {
    product: {
      type: Object as () => Product,
      required: false,
      default: () => {
        return {
          id: null,
        }
      },
    },
  },
  data() {
    return {
      displayAlert: false,
      productId: '',
    }
  },
  mounted() {
    this.productId = this.$route.params.id
    this.displayAlert = false
  },
})
